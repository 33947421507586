body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

main {
  width: 100vw;
  height: 100vh;
  background-color: rgb(23, 16, 31);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.imageWrapper {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: bottom;
  padding-bottom: env(safe-area-inset-bottom);
  object-fit: cover;
  object-position: bottom;
}


footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  transform: translateZ(0);
}

footer button {
  background-color: transparent;
  color: #ffffff;
  padding: 0.2% 4%;
  border: #FFFE9F 1px solid;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 2%;
  display: inline-block;
  text-decoration: none;
}


footer button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

footer button:hover {
  background-color: rgba(28, 26, 26, 0.5);
}

.fixedDiv1 {
  position: absolute;
  z-index: 1;
  top: 73%;
  left: 35%;
  width: 15%;
  height: 15%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.fixedDiv2 {
  position: absolute;
  z-index: 1;
  top: 73%;
  right: 35%;
  width: 15%;
  height: 15%;
  transform: translate(50%, -50%);
  cursor: pointer;
}


@media only screen and (max-width: 500px) {
  .imageWrapper {
    background-image: url('./assets/mobile-background.png')!important;
    width: 100%;
  }
  .fixedDiv1 {
      top: 75%;
      left: 25%;
      width: 30%;
      height: 15%;
  }
  
  .fixedDiv2 {
      right: 17%;
      width: 35%;
      height: 20%;
  }

  @supports (-webkit-touch-callout: none) {
    html, body {
      overflow: hidden;
    }
    .imageWrapper {
      margin-bottom: 140px;
      box-sizing: border-box;
    }
  }
  
}